'use strict';

var v = undefined,
    jLam = {

  util: {

    parallax: document.querySelectorAll('.parallax'),
    closeBtn: document.querySelector('.button-close'),
    shadow: document.querySelector('.shadow'),
    downloadBtn: document.querySelector('.button-download__main'),
    downloadArea: document.querySelector('.shadow-download'),
    awardList: document.querySelectorAll('#awards li'),
    accordionTab: document.querySelector('.accordion-tabs-minimal'),
    accordionTabLinks: document.querySelectorAll('.tab-link'),
    subaccordionTabs: document.querySelectorAll('.subaccordion-tabs'),
    subtabLinks: document.querySelectorAll('.subtab-link'),
    slider: document.getElementById('preview-slider'),

    show: function show() {
      for (var _len = arguments.length, elems = Array(_len), _key = 0; _key < _len; _key++) {
        elems[_key] = arguments[_key];
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = elems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var elem = _step.value;

          elem.classList.add('shown');
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    },
    hide: function hide() {
      for (var _len2 = arguments.length, elems = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        elems[_key2] = arguments[_key2];
      }

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = elems[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var elem = _step2.value;

          elem.classList.add('hidden');
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    getSiblings: function getSiblings(node) {
      // let siblings = [...node.parentElement.children].filter((child) => {
      var siblings = [].slice.call(node.parentElement.children).filter(function (child) {
        return child !== node;
      });
      return siblings;
    }
  },

  init: function init() {
    var _this = this;

    v = this.util;
    this.wowEffects();
    // this.handleScroll(v.parallax);
    if (v.slider) {
      this.autoSlide();
    }
    if (v.shadow) {
      v.shadow.addEventListener('click', function (ev) {
        var target = ev.target;
        if (target.className.indexOf('shadow-download') < 0 && target.parentElement.className.indexOf('shadow-download') < 0) {
          v.shadow.className = 'shadow disappear';
        } else {
          return;
        }
      });
    }
    if (v.downloadBtn) {
      v.downloadBtn.addEventListener('click', function () {
        v.shadow.className = 'shadow appear';
      });
    }
    // if (v.awardList[0]) {
    //   let lists = [...v.awardList];
    //   lists.forEach((list) => {
    //     list.addEventListener('mouseover', this.hoverHistory);
    //   });
    // }
    if (v.accordionTab) {
      v.accordionTab.querySelector('li a').classList.add('is-active');
      v.accordionTab.querySelector('li .tab-content').classList.add('is-open');
      // let tabLinksList = [...v.accordionTabLinks];
      var tabLinksList = [].slice.call(v.accordionTabLinks);
      tabLinksList.forEach(function (link) {
        link.addEventListener('click', _this.expandTab);
      });
    }
    if (v.subaccordionTabs[0]) {
      // [...v.subtabLinks].forEach((tab) => {
      [].slice.call(v.subtabLinks).forEach(function (tab) {
        tab.addEventListener('click', _this.expandJob);
      });
    }
  },
  autoSlide: function autoSlide() {
    var timeOnSlide = 3,
        timeBetweenSlides = 1;
    var animationString = 'animation',
        animation = false,
        keyframePrefix = '',
        domPrefixers = 'Webkit Moz O Khtml'.split(' '),
        pfx = '',
        slider = v.slider;
    if (slider.style.animationName !== undefined) {
      animation = true;
    }
    if (animation === false) {
      for (var i = 0; i < domPrefixers.length; i++) {
        if (slider.style[domPrefixers[i] + 'AnimationName'] !== undefined) {
          pfx = domPrefixers[i];
          animationString = pfx + 'Animation';
          keyframePrefix = '-' + pfx.toLowerCase() + '-';
          animation = true;
          break;
        }
      }
    }
    if (animation === false) {
      // animate in JavaScript fallback
    } else {
        var images = slider.getElementsByTagName('img'),
            firstImg = images[0],
            imgWrap = firstImg.cloneNode(false);
        slider.appendChild(imgWrap);
      }
  },
  expandJob: function expandJob(ev) {
    var target = ev.target;
    console.log(target.classList);
    if (!target.classList.contains('is-active__sub')) {
      ev.preventDefault();
      var tabParent = target.parentElement.parentElement;
      tabParent.querySelector('.is-open__sub').classList.remove('is-open__sub');
      target.parentElement.querySelector('.subtab-content').classList.add('is-open__sub');
      tabParent.querySelector('.is-active__sub').classList.remove('is-active__sub');
      target.classList.add('is-active__sub');
    } else {
      ev.preventDefault();
    }
  },
  expandTab: function expandTab(ev) {
    var target = ev.target;
    console.log(target.classList);
    if (!target.classList.contains('is-active')) {
      ev.preventDefault();
      var tabParent = document.querySelector('.accordion-tabs-minimal');
      tabParent.querySelector('.is-open').classList.remove('is-open');
      target.parentElement.querySelector('.tab-content').classList.add('is-open');
      tabParent.querySelector('.is-active').classList.remove('is-active');
      target.classList.add('is-active');
    } else {
      ev.preventDefault();
    }
  },
  hoverHistory: function hoverHistory(ev) {
    var hoveredList = ev.target;
    ev.target.classList.add('hovered');
    var siblings = v.getSiblings(hoveredList);
    siblings.forEach(function (sibling) {
      sibling.classList.remove('hovered');
    });
    switch (hoveredList.id.slice(-1)) {
      case '1':
        hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_1.png")';
        break;
      case '2':
        hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_2.png")';
        break;
      case '3':
        hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_3.png")';
        break;
      case '4':
        hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_4.png")';
        break;
      default:
        break;
    }
  },
  wowEffects: function wowEffects() {
    // let wows = [...document.querySelectorAll('.wow')];
    var wows = [].slice.call(document.querySelectorAll('.wow'));
    wows.forEach(function (wow) {
      wow.classList.add(wow.getAttribute('data-animation-class'));
    });
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      live: true
    });
    wow.init();
  },
  handleScroll: function handleScroll(divs) {
    var _this2 = this;

    var speed = .1,
        range = 200;
    window.addEventListener('scroll', function () {
      _this2.parallaxScrolling(divs, speed, range);
      var windowWidth = window.innerWidth,
          windowHeight = window.innerHeight;
      var windowScrollTop = window.scrollY;
    });
  },
  parallaxScrolling: function parallaxScrolling(divs, speed, range) {
    var windowYOffset = window.pageYOffset,
        elBackgroundPos = '50% ' + (windowYOffset + speed) + 'px';
    // var divsArray = [...divs];
    var divsArray = [].slice.call(divs);
    divsArray.forEach(function (div) {
      var parallaxHeight = div.clientHeight;
      div.style.backgroundPosition = elBackgroundPos;
    });
  }
};

(function () {
  jLam.init();
})();