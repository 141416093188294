let v,
  jLam = {

    util: {

      parallax          : document.querySelectorAll('.parallax'),
      closeBtn          : document.querySelector('.button-close'),
      shadow            : document.querySelector('.shadow'),
      downloadBtn       : document.querySelector('.button-download__main'),
      downloadArea      : document.querySelector('.shadow-download'),
      awardList         : document.querySelectorAll('#awards li'),
      accordionTab      : document.querySelector('.accordion-tabs-minimal'),
      accordionTabLinks : document.querySelectorAll('.tab-link'),
      subaccordionTabs  : document.querySelectorAll('.subaccordion-tabs'),
      subtabLinks       : document.querySelectorAll('.subtab-link'),
      slider            : document.getElementById('preview-slider'),

      show(...elems) {
        for (let elem of elems) {
          elem.classList.add('shown');
        }
      },

      hide(...elems) {
        for (let elem of elems) {
          elem.classList.add('hidden');
        }
      },

      getSiblings(node) {
        // let siblings = [...node.parentElement.children].filter((child) => {
        let siblings = [].slice.call(node.parentElement.children).filter((child) => {
          return child !== node;
        })
        return siblings;
      }
    },

    init() {
      v = this.util;
      this.wowEffects();
      // this.handleScroll(v.parallax);
      if (v.slider) {
        this.autoSlide();
      }
      if (v.shadow) {
        v.shadow.addEventListener('click', (ev) => {
          let target = ev.target;
          if (target.className.indexOf('shadow-download') < 0 && target.parentElement.className.indexOf('shadow-download') < 0) {
            v.shadow.className = 'shadow disappear';
          } else {
            return;
          }
        });
      }
      if (v.downloadBtn) {
        v.downloadBtn.addEventListener('click', () => {
          v.shadow.className = 'shadow appear';
        });
      }
      // if (v.awardList[0]) {
      //   let lists = [...v.awardList];
      //   lists.forEach((list) => {
      //     list.addEventListener('mouseover', this.hoverHistory);
      //   });
      // }
      if (v.accordionTab) {
        v.accordionTab.querySelector('li a').classList.add('is-active');
        v.accordionTab.querySelector('li .tab-content').classList.add('is-open');
        // let tabLinksList = [...v.accordionTabLinks];
        let tabLinksList = [].slice.call(v.accordionTabLinks);
        tabLinksList.forEach((link) => {
          link.addEventListener('click', this.expandTab);
        });
      }
      if (v.subaccordionTabs[0]) {
        // [...v.subtabLinks].forEach((tab) => {
        [].slice.call(v.subtabLinks).forEach((tab) => {
          tab.addEventListener('click', this.expandJob);
        });
      }
    },

    autoSlide() {
      const timeOnSlide = 3,
            timeBetweenSlides = 1;
      let animationString = 'animation',
          animation = false,
          keyframePrefix = '',
          domPrefixers = 'Webkit Moz O Khtml'.split(' '),
          pfx = '',
          slider = v.slider;
      if (slider.style.animationName !== undefined) {
        animation = true;
      }
      if (animation === false) {
        for (let i = 0; i < domPrefixers.length; i++) {
          if (slider.style[domPrefixers[i] + 'AnimationName'] !== undefined) {
            pfx = domPrefixers[i];
            animationString = `${pfx}Animation`;
            keyframePrefix = '-' + pfx.toLowerCase() + '-';
            animation = true;
            break;
          }
        }
      }
      if (animation === false) {
        // animate in JavaScript fallback
      } else {
        let images = slider.getElementsByTagName('img'),
            firstImg = images[0],
            imgWrap = firstImg.cloneNode(false);
        slider.appendChild(imgWrap);
      }
    },

    expandJob(ev) {
      let target = ev.target;
      console.log(target.classList);
      if (!target.classList.contains('is-active__sub')) {
        ev.preventDefault();
        let tabParent = target.parentElement.parentElement;
        tabParent.querySelector('.is-open__sub').classList.remove('is-open__sub');
        target.parentElement.querySelector('.subtab-content').classList.add('is-open__sub');
        tabParent.querySelector('.is-active__sub').classList.remove('is-active__sub');
        target.classList.add('is-active__sub');
      } else {
        ev.preventDefault();
      }
    },

    expandTab(ev) {
      let target = ev.target;
      console.log(target.classList);
      if (!target.classList.contains('is-active')) {
        ev.preventDefault();
        let tabParent = document.querySelector('.accordion-tabs-minimal');
        tabParent.querySelector('.is-open').classList.remove('is-open');
        target.parentElement.querySelector('.tab-content').classList.add('is-open');
        tabParent.querySelector('.is-active').classList.remove('is-active');
        target.classList.add('is-active');
      } else {
        ev.preventDefault();
      }
    },

    hoverHistory(ev) {
      let hoveredList = ev.target;
      ev.target.classList.add('hovered');
      let siblings = v.getSiblings(hoveredList);
      siblings.forEach((sibling) => {
        sibling.classList.remove('hovered');
      })
      switch (hoveredList.id.slice(-1)) {
        case '1':
          hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_1.png")';
          break;
        case '2':
          hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_2.png")';
          break;
        case '3':
          hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_3.png")';
          break;
        case '4':
          hoveredList.parentElement.style.backgroundImage = 'url("build/images/about_awarded_time_4.png")';
          break;
        default:
          break;
      }
    },

    wowEffects() {
      // let wows = [...document.querySelectorAll('.wow')];
      let wows = [].slice.call(document.querySelectorAll('.wow'));
      wows.forEach(wow => {
        wow.classList.add(wow.getAttribute('data-animation-class'));
      });
      let wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        live: true
      });
      wow.init();
    },

    handleScroll(divs) {
      let speed = .1,
          range = 200;
      window.addEventListener('scroll', () => {
        this.parallaxScrolling(divs, speed, range);
        let windowWidth = window.innerWidth,
            windowHeight = window.innerHeight;
        let windowScrollTop = window.scrollY;
      })
    },

    parallaxScrolling(divs, speed, range) {
      let windowYOffset = window.pageYOffset,
          elBackgroundPos = '50% ' + (windowYOffset + speed) + 'px';
      // var divsArray = [...divs];
      let divsArray = [].slice.call(divs);
      divsArray.forEach((div) => {
        let parallaxHeight = div.clientHeight;
        div.style.backgroundPosition = elBackgroundPos;
      });
    }

};

(function() {
  jLam.init();
})();